import React from 'react';
import { Stack } from '@mui/material';
import SkillsChip from './SkillsChip';

const SkillsList = (props) => {
    const items = props.items
        ? props.items.map((i) => (
            <SkillsChip 
                name={i}
                key={i}
                large={props.large}
                color={props.color}
            />
        )) : null;

  return (
      <Stack
        spacing={0}
        direction="column"
        sx={{ flexWrap: "wrap", gap: "0.25rem" }}
      >
          {items}
      </Stack>
  )
}

export default SkillsList