import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Box, Container, Grid, Typography } from "@mui/material";
import SkillsList from './SkillsList';

const Skill = ({db}) => {

  const [programmingLanguages, setProgrammingLanguages] = useState([]);
  const [frameworksAndLibs, setFrameworksAndLibs] = useState([]);
  const [tools, setTools] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const isMounted = useRef();
    
  const fetchSkills = async (collectionName, setState) => {
    try {
      const collectionRef = collection(db, collectionName);
      const querySnapshot = await getDocs(collectionRef);
      const items = querySnapshot.docs.map(doc => doc.data().name);
      setState(items);
    } catch (err) {
      console.error(`Error fetching from ${collectionName}: `, err);
    }
  };

  useEffect(() => {
    if (isMounted.current) return;

    isMounted.current = true;
    fetchSkills('programming_languages', setProgrammingLanguages);
    fetchSkills('frameworks_and_libs', setFrameworksAndLibs);
    fetchSkills('tools', setTools);
    fetchSkills('upcoming', setUpcoming);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db]);

  return (
      <Box sx={{ minWidth: "100%", bgcolor: "rgba(26, 32, 38, 1)" }}>
          <Container
            fixed
            id="skill"
            sx={{ py: 8, }}
          >

              <Typography variant="h3" sx={{ fontFamily: "Ubuntu", textAlign: "center" }}>
                  SKILLS
              </Typography>

              <Grid
                container
                spacing={{ xs: 8, xl: 14 }}
                sx={{ justifyContent: "center" }}
              >

                <Grid item xs={8} sm={5} md={3} >
                  <Typography variant="h5" sx={{ fontFamily: "Ubuntu", my: 2 }} >
                      Languages
                  </Typography>

                  <SkillsList
                    direction="column"
                    items={programmingLanguages}
                    large
                    color="error"
                  />
                </Grid>

                <Grid item xs={8} sm={5} md={3} >
                    <Typography variant="h5" sx={{ fontFamily: "Ubuntu", my: 2 }}>
                        Frameworks/Libs
                    </Typography>

                    <SkillsList
                    direction="column"
                    items={frameworksAndLibs}
                    large
                    color="info"
                  />
                </Grid>

                <Grid item xs={8} sm={5} md={3} >
                  <Typography variant="h5" sx={{ fontFamily: "Ubuntu", my: 2 }} >
                      Tools
                  </Typography>

                  <SkillsList
                    direction="column"
                    items={tools}
                    large
                    color="success"
                  />
                </Grid>

                <Grid item xs={8} sm={5} md={3} >
                  <Typography variant="h5" sx={{ fontFamily: "Ubuntu", my: 2 }} >
                      Upcoming Skills
                  </Typography>

                  <SkillsList
                    direction="column"
                    items={upcoming}
                    large
                    color="default"
                  />
                </Grid>

              </Grid>

          </Container>
      </Box>
  );
};

export default Skill;