import React from 'react'
import { Box, Typography, Container, Stack, IconButton } from '@mui/material'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { grey, blue } from '@mui/material/colors';
import "./Welcome.css";

const Welcome = () => {
  return (
      <Container 
        maxWidth="100%"
        id="home"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: 'url(/BannerNew.jpg)',
      }}>
        <Box sx={{
          textAlign: "center",
          width:'100%',
        }}>

          <Typography variant='h2' sx={{
            color: "white",
            fontFamily: "Ubuntu"
          }}>
            Francesco Di Marzo
          </Typography>

          <Typography variant='h4' sx={{
            color: "white",
            fontFamily: "Ubuntu"
          }}>
            {"<Web developer />"}
          </Typography>

          <Stack direction="row" spacing={1} 
            sx={{
              justifyContent: "center",
          }}>
            <IconButton href="https://www.linkedin.com/in/francesco-di-marzo/" target="__blank">
              <LinkedInIcon sx={{ color: grey[50], "&:hover": { color: blue[500] }, fontSize: 40 }} />
            </IconButton>
            <IconButton href="https://github.com/Barabba97" target="__blank">
              <GitHubIcon sx={{ color: grey[50], "&:hover": { color: grey[900] }, fontSize: 40 }} />
            </IconButton>
          </Stack>

        </Box>
      </Container>
  );
};

export default Welcome;