import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { Avatar, Typography, Box, Container } from '@mui/material';

const About = ({db}) => {

    const [paragraphs, setParagraphs] = useState({});
  const isMounted = useRef(false);

  const fetchParagraphs = async () => {
    try {
      const docRef = doc(db, 'aboutSection', 'paragraphs');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setParagraphs(docSnap.data());
      } else {
        console.error("No such document!");
      }
    } catch (err) {
      console.error("Error fetching document: ", err);
    }
  };

  useEffect(() => {
    if (isMounted.current) return;

    isMounted.current = true;
    fetchParagraphs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db]);

  return (
      <React.Fragment>
        <Box sx={{ bgcolor: "rgba(26, 32, 38, 1)", minWidth: "100%"}}>
            <Container
                id="about" 
                fixed 
                sx={{
                    textAlign: "left",
                    py: 8,
                }}
            >
                <Box 
                    sx={{ 
                        display: "flex", 
                        flexDirection: "row", 
                        justifyContent: "center", 
                        alignItems: "center",
                    }}
                >
                    <Avatar 
                        alt="Barabba" 
                        src="/Avatar.png" 
                        sx={{ 
                        color: "white", 
                        border: "none",
                        borderRadius: "0px",
                        bgcolor: "transparent", 
                        width: 140, 
                        height: 140,
                        marginRight: "20px"
                        }} 
                        variant="rounded"
                    >
                        F
                    </Avatar>

                    <Typography variant="h2" sx={{ fontFamily: "Ubuntu", fontWeight: "regular" }}>
                        ABOUT
                    </Typography>
                </Box>
                <br />
                <Typography 
                    variant="h6" 
                    component="div" 
                    sx={{
                        fontFamily: "Ubuntu",
                        fontWeight: "regular",
                        padding: "20px",
                    }}
                >
                    {paragraphs.paragraph_1}
                </Typography>
                <Typography 
                    variant="h6" 
                    component="div" 
                    sx={{
                        fontFamily: "Ubuntu",
                        fontWeight: "regular",
                        padding: "20px",
                    }}
                >
                    {paragraphs.paragraph_2}
                </Typography>
                <Typography 
                    variant="h6" 
                    component="div" 
                    sx={{
                        fontFamily: "Ubuntu",
                        fontWeight: "regular",
                        padding: "20px",
                    }}
                >
                    {paragraphs.paragraph_4}
                </Typography>
                <Typography 
                    variant="h6" 
                    component="div" 
                    sx={{
                        fontFamily: "Ubuntu",
                        fontWeight: "regular",
                        padding: "20px",
                    }}
                >
                    {paragraphs.paragraph_3}
                </Typography>

            </Container>
        </Box>
      </React.Fragment>
  )
};

export default About;