import React from 'react';
import { Chip } from '@mui/material';

const SkillsChip = (props) => {
  return (
    <Chip 
        sx={{
            fontFamily: "Ubuntu",
            p: 2,
            borderRadius: 0,
            fontSize: "1.05rem",
            flexGrow: 0
        }}
        label={props.name}
        variant="outlined"
        color={props.color}
    />
  )
}

export default SkillsChip