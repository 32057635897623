import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Container, Typography, List, ListItemButton, ListItemIcon, ListItemText, Divider, Icon } from '@mui/material';

const Path = ({db}) => {

    const collectionRef = collection(db, 'learningPath');
    const [learningPath, setLearningPath] = useState([]);
    const isMounted = useRef();
    
    const fetchLearningPath = async () => {
        let items = [];

        try {
            const querySnapshot = await getDocs(collectionRef);
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            })
            setLearningPath(items);
        } catch(err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if(isMounted.current) { return }
        
        isMounted.current = true;
        fetchLearningPath();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container fixed id="path" sx={{
            bgcolor: "rgba(26, 32, 38, 1)",
            minWidth: "100%",
            borderTop: "1px solid gray",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            py: 8,
        }}>
            <Typography variant="h3" sx={{ fontFamily: "Ubuntu", textAlign: "center", marginBottom: "1rem"}}>
                PATH
            </Typography>

            <List
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    width: {
                        xs: "90%", // Mobile devices
                        sm: "80%", // Small screens
                        md: "70%", // Medium screens
                        lg: "70%", // Large screens
                        xl: "60%", // Extra-large screens
                    },
                    height: "100%"
                }}
            >
                {learningPath.map((item, index) => {
                    return(
                        <>
                        <ListItemButton href={item.link} target="_blank" key={index} sx={{textAlign: item.align }}>
                            <ListItemIcon sx={{display: item.align === "left" ? "flex" : "none" }}>
                                <Icon baseClassName={item.icon[0]} className={item.icon[1]} sx={{ fontSize: 30 }}/>
                            </ListItemIcon>

                            <ListItemText 
                                primary={
                                    <React.Fragment>
                                        <Typography fontSize="20px">
                                            {item.date}
                                        </Typography>
                                    </React.Fragment>
                                } 
                                secondary={
                                    <React.Fragment>
                                        <Typography fontSize="15px">
                                            {item.description}
                                        </Typography>
                                    </React.Fragment>
                                } 
                                sx={{ paddingRight: "30px", fontSize: "100px"}} />
                            
                            <ListItemIcon sx={{display: item.align === "right" ? "flex" : "none" }}>
                                <Icon baseClassName={item.icon[0]} className={item.icon[1]} sx={{ fontSize: 30 }}/>
                            </ListItemIcon>
                        </ListItemButton>
                        <Divider sx={{ display: index < (learningPath.length - 1) ? "flex" : "none", width: "95%", }} orientation="horizontal" />
                        </>
                    );
                })}
            </List>

        </Container>
    )
}

export default Path;