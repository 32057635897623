import { Container, Typography } from '@mui/material';
import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

  return (
      <Container sx={{ bgcolor: "rgba(23, 32, 38, 1)", minWidth: "100%" , display: "flex", flexDirection: "center", justifyContent: "center", alignItems: "center", borderTop: "1px solid grey"}}>
            <img height="30px" width="60px" alt="FDM" id="logo" src="/FDMLogo.png"></img>
            <Typography sx={{ paddingLeft: "10px", fontFamily: "Ubuntu", display: "flex", py: 2, justifyContent: "center", alignItems: "baseline", gap: 1 }}>
                © {currentYear} Francesco Di Marzo | All Rights Reserved.
            </Typography>
      </Container>
  );
};

export default Footer;