import React from 'react';
import './App.css';
import Homepage from './components/Homepage';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { darkTheme } from './components/theme';

function App() {
  const theme = createTheme(darkTheme);

  return(
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline>
            <Homepage />
        </CssBaseline>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
