import React from 'react';
import NavigationBar from './NavigationBar';
import About from './About';
import Welcome from './Welcome/Welcome';
import ProjectsCarousel from './ProjectsCarousel';
import Skill from './Skill';
import Footer from './Footer';
import Path from './Path/Path';
// eslint-disable-next-line no-unused-vars
import { app, db } from '../firebaseConfig';

const Homepage = () => {
  return (
    <React.Fragment>
      <NavigationBar />
      <Welcome />
      <About db={db}/>
      <Path db={db} />
      <ProjectsCarousel db={db} />
      <Skill db={db} />
      <Footer />
    </React.Fragment>
  );
};

export default Homepage;