import React, { useState } from 'react';
import { AppBar, Button, Toolbar, useScrollTrigger, Drawer, Box } from '@mui/material';
import { grey, deepOrange } from '@mui/material/colors';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';

const AppBarOptions = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 778,
    target: window,
  });
  //This code is for the hiding navbar
  /*const secondTrigger = useScrollTrigger({
    disableHysteresis: false,
    threshold: 100,
    target: window,
  })*/
  /*<Slide appear="false" in={!secondTrigger}>*/
  /*</Slide>*/

  return (
      <AppBar
        sx={{ 
          position: { xs: "start", sm: "flex", md: "fixed" }, 
          background: trigger ? deepOrange[900] : "transparent",
          boxShadow: "none",
          transition: ".5 background" 
        }}
      >
        {children}
      </AppBar>
  )
};

const toolbarButtons = (data) => {
  return (
    <Button
      key={data}
      variant='h6'
      sx={{color: grey[50], "&:hover": { color: grey[500] }}}
      onClick={() =>
        document
          .getElementById(data)
          .scrollIntoView({ block: "start", behavior: "smooth"})
      }
    >
      {data}
    </Button>
  )
};

const NavigationBar = (props) => {
  const [drawer, setDrawer] = useState(false);
  const items = ["home", "about", "path", "projects", "skill"];

  return (
    <AppBarOptions {...props}>
      
        <Toolbar sx={{ display: {xs: "none", sm: "flex", md: "flex"}, flexDirection: "row", justifyContent: "center"}}>

          <Box sx={{position: "absolute", left: "0", marginLeft: "25px", marginTop: "10px"}} >
            <img height="35px" width="65px" alt="FDM" src="/FDMLogo.png"></img>
          </Box>

          { 
            items.map(toolbarButtons, this)
          }
          
          <Button 
            variant="h6" 
            sx={{color: grey[50], "&:hover": { color: grey[500] }}}
            href="mailto:francesco.dimarzo.mail@gmail.com"
            endIcon={<EmailIcon/>}
          >
            Contact
          </Button>
        </Toolbar>

        <Toolbar sx={{ display: {xs: "flex", sm: "none", md: "none"}, flexDirection: "row", justifyContent: "right"}}>          
          
          <Box sx={{position: "absolute", left: "0", marginLeft: "25px", marginTop: "10px"}} >
            <img height="35px" width="65px" alt="FDM" src="/FDMLogo.png"></img>
          </Box>

          <Button 
            variant="h6" 
            onClick={() =>
              document
                .getElementById("home")
                .scrollIntoView({ block: "start", behavior: "smooth" })
            }
            sx={{ color: grey[50], "&:hover": { color: grey[500] }}}
          >
          <ArrowUpIcon />
          </Button>

          <Button 
            onClick={() => setDrawer(true)}
            sx={{color: grey[50], "&:hover": { color: grey[500] }}}
          >
            <MenuIcon />
          </Button>

          <Drawer
            anchor={"right"}
            open={drawer}
            onClose={() => setDrawer(false)}
            PaperProps={{ style: {background: deepOrange[900]}}}
            SlideProps={{ easing: "ease-in-out" }}
          >

            <Button
              sx={{color: grey[50], "&:hover": { color: grey[500] }}}
              onClick={() => setDrawer(false)}
            >
              <CloseIcon />
            </Button>

            {
              items.map(toolbarButtons, this)
            }

            <Button 
              variant="h6" 
              sx={{color: grey[50], "&:hover": { color: grey[500] }}}
              href="mailto:francesco.dimarzo.mail@gmail.com"
              endIcon={<EmailIcon/>}
            >
              Contact
            </Button>

          </Drawer>
        </Toolbar>
    </AppBarOptions>
  );
};

export default NavigationBar;