// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBuMtjs08iD-xda1G9PhfGE1-GIxA5SgAg",
  authDomain: "fdm-portfolio.firebaseapp.com",
  projectId: "fdm-portfolio",
  storageBucket: "fdm-portfolio.appspot.com",
  messagingSenderId: "415160620906",
  appId: "1:415160620906:web:a6b5059da12cca7ccab713"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);