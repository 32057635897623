import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Container, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { collection, getDocs } from 'firebase/firestore';

import { grey, blue, green } from '@mui/material/colors';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CodeIcon from '@mui/icons-material/Code';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ProjectsCarousel = ({db}) => {

  const collectionRef = collection(db, 'projects');
  const [projects, setProjects] = useState([]);
  const isMounted = useRef();
  
  const fetchProjects = async () => {
    let proj = [];
    try {
      const querySnapshot = await getDocs(collectionRef);
      querySnapshot.forEach((doc) => {
        proj.push(doc.data());
      })
      setProjects(proj);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if(isMounted.current) {
      return
    }
    
    isMounted.current = true;
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let items = projects.map((project, i) => {

    return (
        <Card 
          sx={{ 
            borderRadius: "0px", 
            bgcolor: "rgba(21, 21, 21, 0.8)", 
            color: "white",
            margin: "auto",
            width: 300,
          }}
          key={i}
        >
          <CardActionArea>
            <CardMedia 
              component="img"
              alt="Project"
              height="140"
              image={project.image}
            />
            <CardContent>
              <Typography 
                variant="h5" 
                sx={{
                  fontFamily: "Ubuntu"
                }} 
                gutterBottom
              >
                {project.name}
              </Typography>
              <Typography 
                variant="body2" 
                height="140"
                sx={{
                  fontFamily: "Ubuntu",
                  marginBottom: "1rem"
                }}
              >
                {project.description}
              </Typography>
              <Typography 
                variant="h7" 
                sx={{ 
                  fontWeight: "italic",
                }} 
                color="text.secondary"
              >
                {project.technologies}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button 
              size="small" 
              sx={{ 
                color: grey[50], 
                "&:hover": { color: blue[500], background: grey[800] }}}
              endIcon={<CodeIcon />}
              href={project.githubLink}
              target="_blank"
              disabled={project.githubLink ? false : true}
            >
              Code
            </Button>

            <Button 
              size="small" 
              sx={{color: grey[50], "&:hover": { color: green["A700"], background: grey[800] }}}
              endIcon={<PlayArrowIcon />} 
              href={project.liveLink}
              target="_blank"
              disabled={project.liveLink ? false : true}
            >
              Live
            </Button>
          </CardActions>
        </Card>
    );
  });

  const responsive = {
    0: {
      items: 1,
    },
    568: {
      items: 1,
    },
    800: {
      items: 2,
    },
    1024: {
      items: 3,
    },
    2048: {
      items: 4,
    },
  };

  const renderNextButton = ({ isDisabled }) => {
    return <ArrowForwardIosIcon 
              sx={{ 
                position: "absolute", 
                right: 0, 
                top: 250,
                cursor: "pointer",
                color: grey[50], "&:hover": { color: grey[500] }
              }}
            />
  };

  const renderPrevButton = ({ isDisabled }) => {
    return <ArrowBackIosIcon 
              sx={{
                position: "absolute", 
                left: 0, 
                top: 250, 
                cursor: "pointer",
                color: grey[50], "&:hover": { color: grey[500] }
              }} 
            />
  };

  console.log(items);

  return (
        <Container
          id="projects" 
          component="div" 
          sx={{ 
            minWidth: "100%",
            margin: "auto",
            bgcolor: "rgba(26, 32, 38, 1)",
            display: "flex", 
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            borderTop: "1px solid grey",
            borderBottom: "1px solid grey",
            py: 8,
          }}
        >

          <Typography variant="h3" sx={{ fontFamily: "Ubuntu", textAlign: "center", marginBottom: "2rem" }}>
            PROJECTS
          </Typography>

          <AliceCarousel
            mouseTracking
            touchTracking
            autoPlay
            autoPlayInterval={1000}
            animationDuration={2000}
            responsive={responsive}
            items={items}
            infinite
            keyboardNavigation
            renderNextButton={renderNextButton}
            renderPrevButton={renderPrevButton}
            disableDotsControls
          />
        </Container>
  );
};

export default ProjectsCarousel;